@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.topnav {
  font-family: "Inter";
  height: 11vh;
  flex-direction: row;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  border-spacing: 0;
  border-bottom-color: #dcdcdc;
  background-color: white;
}

.logo {
  width: 20%;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
}

/*---- Create Post ----*/

.create-post-button {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 1rem;
  right: 12rem;
  padding: 1rem 0.5rem;
  z-index: 1;
}

.create-post-button:hover {
  background-color: #ddd;
  border-radius: 5px;
}

.create-post-button #add-post-icon {
  font-size: 1.5rem;
  padding: 0.1rem;
  margin-right: 3px;
}

.create-post-button p {
  padding-right: 5px;
}

.nav-error-message {
  display: flex;
  width: fit-content;
  padding: 0.5rem 1rem;
  border: rgb(150, 0, 0) solid 1px;
  border-radius: 25px;
  align-items: center;
  position: absolute;
  top: 4.5rem;
  left: 9rem;
  background-color: #fff;
}

.nav-error-message #error-message-icon {
  color: rgb(150, 0, 0);
  margin-right: 0.5rem;
}

/*----- Search bar -----*/

.searchbar {
  width: 56%;
  min-width: 200px;
  left: 11%;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.search-input-wrapper {
  height: 2.5rem;
  width: 100%;
  padding: 0 15px;
  font-size: 1.15rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 8px #ddd;
}

.search-input-wrapper input {
  height: 1.5rem;
  width: 100%;
  margin-left: 10px;
  background-color: transparent;
  border: none;
}

.search-input-wrapper input:focus {
  outline: none;
}

#search-icon {
  cursor: pointer;
}

#close-icon {
  margin-left: -0.5rem;
  margin-right: -0.3rem;
  width: 2rem;
  cursor: pointer;
}

/*----- Searchbar Results -----*/

.results-list {
  width: 40vw;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 8px #ddd;
  border-radius: 2px;
  margin-top: 3rem;
  overflow-y: scroll;
  position: absolute;
  cursor: pointer;
}

.search-result {
  padding: 10px 20px;
  text-align: left;
}

.search-result:hover {
  background-color: #efefef;
}

/*----- Links -----*/

.nav-links {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  z-index: 1;
}

.nav-links .page {
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  margin: 0.3rem 1rem 0 0.5rem;
  cursor: pointer;
}

.nav-signin-buttons {
  margin-top: 0.75rem;
}

.nav-links .login-button {
  font-size: 1rem;
  padding: 0.6rem;
  margin: 0 1rem 0 0.5rem;
  border-radius: 5px;
  border: black solid 1px;
  color: black;
  background-color: white;
}

.nav-links .signup-button {
  font-size: 1rem;
  padding: 0.7rem;
  margin: 0 1rem 0 0rem;
  border-radius: 5px;
  color: white;
  background-color: black;
}

.nav-links .page:hover {
  background-color: #ddd;
}

.nav-links .login-button:hover {
  background-color: #ddd;
}

.nav-links .signup-button:hover {
  background-color: #272727;
}

/*-- Dropdown menu --*/

.nav-links #nav-dropdown-button {
  font-size: 2.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  margin: 0.3rem 1rem 0 0.5rem;
  cursor: pointer;
}

.nav-links #nav-dropdown-button:hover {
  background-color: #ddd;
}

.nav-links .nav-dropdown-menu {
  position: absolute;
  top: 3.8rem;
  right: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 8px #272727;
  padding: 1rem;
  width: 12rem;
}

.nav-links .nav-dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.nav-links .nav-dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.nav-links .nav-dropdown-menu::before {
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: #fff;
  top: -0.7rem;
  right: 10%;
  border-top: #272727 outset 1px;
  border-left: #272727 outset 1px;
  transform: rotate(45deg);
}

.nav-links .nav-dropdown-menu li {
  padding: 2rem 0.5rem;
  text-align: left;
}

.nav-links .nav-dropdown-menu hr {
  color: gray;
  border-style: inset;
  border-width: 1px;
  margin: 1em auto -0.5em;
}

.nav-dropdown-menu .nav-dropdown-profile {
  display: flex;
  flex-direction: row;
  width: fit-content;
  max-width: 90%;
  overflow-x: scroll;
}

.nav-dropdown-menu .nav-dropdown-profile::-webkit-scrollbar {
  display: none;
}

.nav-links .nav-dropdown-menu hr.nav-profile-hr {
  color: gray;
  border-style: inset;
  border-width: 1px;
  margin: 0em auto -0.5em;
}

.nav-dropdown-menu .nav-profile-img {
  margin-right: 0.5rem;
  width: 2rem;
  float: left;
  border-radius: 50%;
}

.nav-dropdown-menu .name-username {
  float: left;
}

.nav-dropdown-menu p.name {
  margin-top: -0.1rem;
  color: #000;
  font-size: 14px;
  font-variation-settings: "wght" 450;
}

.nav-dropdown-menu p.username {
  margin-top: -0.3rem;
  color: #5e5e5e;
  font-size: 12px;
}

.nav-dropdown-item #nav-dropdown-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  float: left;
}

.nav-dropdown-item p {
  float: left;
}