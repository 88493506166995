@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.main-container {
  height: fit-content;
  width: 100%;
  margin: auto;
}

.project-vertical-spacer {
  width: 2%;
}

.project-horizontal-spacer {
  height: 30px;
}

.project-horizontal-line {
  width: 90%;
  height: 2px;
  background-color: #c7c7c7;
  margin: 30px auto;
}

/*------------------------------------------------*/
/*                  Project Title                 */
/*------------------------------------------------*/

.project-title {
  width: 88%;
  font-family: "Inter";
  color: #000000;
  margin: 40px auto 40px auto;
}

.project-title h1 {
  font-size: 32px;
  font-style: italic;
  font-variation-settings: "wght" 700;
  text-align: left;
  line-height: 1.2;
}

/*------------------------------------------------*/
/*               Project Info Group               */
/*------------------------------------------------*/

.project-info {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
}

.project-info-column {
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: fit-content;
  float: left;
  margin-left: 6%;
}

.project-info-column h1 {
  font-family: "Inter";
  font-size: 18px;
  text-align: left;
  font-variation-settings: "wght" 500;
  line-height: 1.5;
  color: #000000;
}

.project-info-column p {
  font-family: "Inter";
  font-size: 16px;
  text-align: left;
  font-variation-settings: "wght" 475;
  line-height: 1.5;
  color: #000000;
  padding-top: 75px;
}

.project-info-column h2 {
  font-family: "Inter";
  font-size: 14px;
  text-align: left;
  font-variation-settings: "wght" 550;
  font-style: italic;
  line-height: 1.5;
  color: #334f87;
  padding-top: 15px;
}

.project-image-column {
  width: 30%;
  margin-right: 6%;
  img {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    object-fit: cover;
  }
}

.project-tags {
  margin: 25px auto auto auto;
  align-items: left;
}

.project-tags p {
  margin: 8px 0 0px 8px;
  padding: 6px 12px 6px 12px;
  width: fit-content;
  font-family: "Inter";
  font-size: 16px;
  color: #000000;
  font-variation-settings: "wght" 550;
  background-color: #bccdee;
  border-radius: 8px;
  float: left;
}

/*------------------------------------------------*/
/*              User Interaction Group            */
/*------------------------------------------------*/

.project-inter-group {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
}

.project-curr-column {
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: fit-content;
  float: right;
  margin-right: 6%;
}

.project-curr-block {
  width: 100%;
  border-radius: 8px;
  background-color: #f2e7e7;
  padding: 20px 4%;
}

.project-curr-block h1 {
  width: fit-content;
  text-align: left;
  font-family: "Inter";
  font-size: 22px;
  font-variation-settings: "wght" 600;
  margin-bottom: 10px;
}

.project-curr-block li {
  font-family: "Inter";
  font-size: 14px;
  text-align: left;
  font-variation-settings: "wght" 475;
  line-height: 1.5;
  color: #000000;
}

.project-curr-block p {
  font-family: "Inter";
  font-size: 12px;
  text-align: right;
  font-variation-settings: "wght" 425;
  line-height: 1.5;
  color: #3a3a3a;
  margin-top: 10px;
}

.project-comm-column {
  flex-direction: column;
  align-items: left;
  width: 60%;
  height: fit-content;
  float: left;
  margin-left: 6%;
  img {
    width: 100%;
    height: 525px;
    border-radius: 10px;
    object-fit: contain;
  }
}

.project-comm-block {
  width: 100%;
  background-color: #f2e7e7;
  border-radius: 8px;
  padding: 20px 3%;
}

/*------------------------------------------------*/
/*                 Related Group                  */
/*------------------------------------------------*/
.project-related-group {
  width: 90%;
  height: fit-content;
  margin: 30px auto;
  padding: 20px 3%;
  border-top: 2px solid #c7c7c7;
}

.project-related-title {
  height: fit-content;
}

.project-related-title h1 {
  width: fit-content;
  text-align: left;
  font-family: "Inter";
  font-size: 28px;
  font-variation-settings: "wght" 600;
  margin-bottom: 10px;
}

.project-related-row {
  display: flex;
  height: fit-content;
}

.project-related-item {
  flex-direction: column;
  width: 25%;
}

.project-related-item-image {
  img {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
    height: 175px;
  }
}

.project-related-item-desc h1 {
  font-family: "Inter";
  font-size: 14px;
  text-align: left;
  font-style: italic;
  font-variation-settings: "wght" 500;
  line-height: 1.5;
  color: #000000;
  margin: 5px;
}

.project-related-item-desc p {
  font-family: "Inter";
  font-size: 11px;
  text-align: left;
  font-variation-settings: "wght" 400;
  line-height: 1.5;
  color: #828282;
  margin: 5px;
}
