@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.main-container {
  font-family: "Inter";
  height: fit-content;
  width: 100%;
  margin: auto;
  text-align: center;
  overflow-x: hidden;
}

.vertical-spacer {
  width: 4%;
}

.horizontal-spacer {
  height: 75px;
}

/*------------------------------------------------*/
/*                  About Title                   */
/*------------------------------------------------*/

.about-title {
  height: fit-content;
  width: 90%;
  padding: 20px;
  margin: 40px auto;
}

.tag-row {
  margin-left: 60px;
}

.tag-row h1 {
  font-size: 20px;
  font-variation-settings: "wght" 700;
  text-align: left;
  margin-left: 10px;
}

/*------------------------------------------------*/
/*               Mission Container                */
/*------------------------------------------------*/

.about-mission {
  display: inline-block;
  height: fit-content;
  width: 90%;
  background-color: #eedadd;
  border-radius: 10px;
  padding: 40px;
}

.about-mission-left {
  width: 48%;
  float: left;
  img {
    margin-top: 40px;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    object-fit: cover;
  }
}

.about-mission-right {
  width: 48%;
  float: right;
  img {
    margin-bottom: 40px;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    object-fit: cover;
  }
}

.about-mission h1 {
  font-size: 28px;
  text-align: center;
  font-variation-settings: "wght" 600;
  line-height: 1.1;
  margin-bottom: 20px;
}

.about-mission p {
  font-size: 20px;
  text-align: center;
  font-variation-settings: "wght" 420;
  line-height: 1.3;
  color: #4b4b4b;
}

/*------------------------------------------------*/
/*              Qualities Container               */
/*------------------------------------------------*/

.about-qual {
  display: flex;
  justify-content: space-around;
  height: fit-content;
  width: 80%;
  margin: 50px auto;
}

.about-qual-left {
  display: block;
  width: 45%;
  height: fit-content;
  img {
    margin: auto;
    height: 15%;
    width: 15%;
  }
}

.about-qual-right {
  display: block;
  width: 45%;
  height: fit-content;
  img {
    margin: auto;
    height: 15%;
    width: 15%;
  }
}

.about-qual h1 {
  font-size: 20px;
  text-align: center;
  font-variation-settings: "wght" 600;
}

.about-qual p {
  font-size: 18px;
  text-align: center;
  font-variation-settings: "wght" 400;
  line-height: 1.5;
  color: #606060;
}

/*------------------------------------------------*/
/*              Cofounder Container               */
/*------------------------------------------------*/

.about-cofound {
  display: block;
  height: fit-content;
  width: 90%;
  background-color: #c5dbea;
  border-radius: 10px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
}

.about-cofound-title {
  display: inline;
  height: fit-content;
  width: 100%;
  margin-bottom: 20px;
}

.about-cofound-horizontal {
  display: flex;
  height: fit-content;
  width: 100%;
}

.about-cofound-column {
  align-items: center;
  display: inline-block;
  flex: 50%;
  margin-right: 10px;
  img {
    width: 250px;
    height: 250px;
    margin: auto;
    border-radius: 100%;
  }
}

.about-cofound h1 {
  font-size: 35px;
  text-align: center;
  font-variation-settings: "wght" 600;
}

.about-cofound h2 {
  font-size: 30px;
  text-align: center;
  font-variation-settings: "wght" 600;
}

.about-cofound p {
  font-size: 20px;
  text-align: center;
  font-variation-settings: "wght" 400;
  line-height: 1.5;
  color: #606060;
}
