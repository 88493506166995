@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.settings-main-container {
    font-family: 'Inter';
    background-color: #f6f6f6;
    height: 100%;
    padding-bottom: 2rem;
}

.settings-main-container h1 {
    font-size: 2rem;
    font-variation-settings: 'wght' 500;
    margin-left: 7rem;
}

.settings-main-container .settings-container {
    margin: 1rem 0 1rem 7rem;
    display: flex;
    flex-direction: row;
}

.settings-main-container .settings-left-bar {
    display: flex;
    flex-direction: column;
    width: 15rem;
    height: fit-content;
    background-color: white;
    border: lightgray solid 1px;
    padding: 1rem 0;
}

.settings-main-container .settings-left-bar button {
    text-align: left;
    font-size: 1.3rem;
    font-variation-settings: 'wght' 400;
    padding: 0.75rem 1rem;
    display: flex;
    flex-direction: row;
}

.settings-main-container .settings-left-bar button:hover {
    background-color: #f2f2f2;
    border-right: #cdcdcd solid 4px;
}

.settings-main-container .settings-left-bar button.active {
    background-color: #f2f2f2;
    border-right: #cdcdcd solid 4px;
}

.settings-main-container .settings-left-bar #settings-icon {
    margin: 0.3rem 0.5rem 0 0;
}

.settings-main-container .settings-info-container {
    display: flex;
    flex-direction: column;
    width: 65%;
}

.settings-info-container .profile-settings {
    padding: 2rem;
    margin-left: 2rem;
    background-color: #fff;
    border: lightgray solid 1px;
}

.profile-settings .profile-image-settings {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-settings .profile-image-settings img {
    width: 10rem;
    border-radius: 50%;
}

.profile-settings .profile-image-settings button {
    width: 8rem;
    height: 3rem;
    font-variation-settings: 'wght' 500;
    border-radius: 4px;
}

.profile-settings .profile-image-settings .button-1 {
    margin-left: 5rem;
    color: white;
    background-color: #3258ff;
    width: 8rem;
    height: 3rem;
    font-size: 1rem;
    font-variation-settings: 'wght' 550;
    text-align: center;
    align-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.profile-settings .profile-image-settings input[type="file"] {
    display: none;
}

.profile-settings .profile-image-settings .button-2 {
    margin-left: 1rem;
    background-color: #f2f2f2;
}

.profile-settings .profile-settings-inputs {
    padding: 1rem;
    margin-top: 1rem;
}

.profile-settings-inputs .profile-settings-row {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.profile-settings-row .settings-input-item {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
}

.profile-settings-row .settings-input-item h1 {
    font-size: 1rem;
    margin-left: 0;
    padding: 0.5rem 0;
}

.profile-settings-row .settings-input-item input {
    font-size: 1rem;
    padding: 1rem 1rem;
    width: 20rem;
    border: lightgray solid 1px;
    border-radius: 4px;
}

.profile-settings-row .settings-input-item input:focus {
    background-color: #eff2fd;
}

.profile-settings .save-button {
    margin: 1rem 0 0 38rem;
    width: 8rem;
    height: 3rem;
    font-variation-settings: 'wght' 500;
    border-radius: 4px;
    color: #fff;
    background-color: #3258ff;
}

/*-- Error Message Container --*/

.settings-error-message {
    display: flex;
    width: fit-content;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: rgb(150, 0, 0) solid 1px;
    border-radius: 25px;
    align-items: center;
    position: absolute;
    bottom: -2.5rem;
    margin-left: 34%;
    left: 0;
    right: 0;
}

.settings-error-message #error-message-icon {
    color: rgb(150, 0, 0);
    margin-right: 0.5rem;
}

.settings-info-container .inactive-settings {
    padding: 2rem;
    height: 40rem;
    margin-left: 2rem;
    background-color: #fff;
    border: lightgray solid 1px;
    align-content: center;
}

.settings-info-container .inactive-settings p {
    text-align: center;
    font-variation-settings: 'wght' 500;
    font-size: 20px;
}