@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.update-profile-main-container {
  font-family: "Inter";
  height: fit-content;
  width: 100%;
  align-content: center;
}

.update-profile-main-container h1 {
  margin-left: 5vw;
  font-size: 30px;
  font-variation-settings: "wght" 600;
}

.update-profile-vertical-spacer {
  height: 2rem;
}

.update-profile-small-vertical-spacer {
  height: 1rem;
}

.update-profile-horizontal-spacer {
  width: 20%;
}

/*----------------------------------*/
/*        Top Columns Block         */
/*----------------------------------*/
.update-profile-column-container {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
  align-content: flex-start;
}

.update-profile-left-column {
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  height: fit-content;
  float: left;
}

.update-profile-right-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: fit-content;
  float: right;
}

/*------------------------------*/

.update-profile-left-column .update-profile-image-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.update-profile-image-settings img {
  width: 10rem;
  border-radius: 50%;
}

.update-profile-image-settings button {
  width: 8rem;
  height: 3rem;
  font-variation-settings: 'wght' 500;
  border-radius: 4px;
}

.update-profile-image-settings .image-upload-button {
  margin-left: 5rem;
  color: white;
  background-color: #3258ff;
  width: 8rem;
  height: 3rem;
  font-size: 1rem;
  font-variation-settings: 'wght' 550;
  text-align: center;
  align-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.update-profile-image-settings input[type="file"] {
  display: none;
}

.update-profile-image-settings .image-delete-button {
  margin-left: 1rem;
  background-color: #f2f2f2;
}

/*------------------------------*/
/*          Inner Block         */
/*------------------------------*/
.update-profile-inner-container {
  width: 90%;
  height: fit-content;
  margin: 20px auto;
  background-color: #ffffff;
  box-shadow: 0 0 8px #ddd;
  padding: 40px;
  border-radius: 8px;
}

.update-profile-inner-container h2 {
  text-align: left;
  font-family: "Inter";
  font-size: 20px;
  font-variation-settings: "wght" 600;
}

.update-profile-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

/*----------------------------------------------*/
/*        Update Profile Search Feature         */
/*----------------------------------------------*/
.update-profile-search-input-wrapper {
  height: 2rem;
  width: 100%;
  padding: 0 15px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #9f9f9f !important;
  border-radius: 4px;
}

.update-profile-search-input-wrapper input {
  height: 1.5rem;
  width: 100%;
  margin-left: 10px;
  background-color: transparent;
  border: none;
}

.update-profile-search-input-wrapper input:focus {
  outline: none;
}

#basic-icon {
  cursor: pointer;
}

#close-icon {
  margin-left: -0.5rem;
  margin-right: -0.3rem;
  width: 2rem;
  cursor: pointer;
}

.update-profile-results-list {
  width: 35%;
  max-height: 10rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 8px #ddd;
  border-radius: 2px;
  margin-top: 2rem;
  overflow-y: scroll;
  position: absolute;
  cursor: pointer;
  z-index: 50;
}

.update-profile-search-result {
  padding: 10px 20px;
  text-align: left;
}

.update-profile-search-result:hover {
  background-color: #efefef;
}

/*--------------------------------------*/
/*          Inputted Info Items         */
/*--------------------------------------*/
.profile-inputted-item-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  margin-top: 0.5rem;
}

.profile-inputted-item {
  display: flex;
  flex-direction: row;
}

.profile-inputted-item p {
  margin-bottom: 5px;
  padding: 6px 9px 6px 9px;
  font-size: 14px;
  color: #3d3d3d;
  background-color: #bccdee;
  border-radius: 8px;
  font-variation-settings: "wght" 550;
}

.profile-inputted-item #profile-delete-inputted-item {
  margin: 0.5rem 0.5rem 0 0;
  font-size: 1rem;
  cursor: pointer;
}

/*--------------------------------------*/
/*          Description Items           */
/*--------------------------------------*/
.description-input-wrapper {
  margin: 1rem 0;
  padding: 1rem 1rem 0.5rem;
  width: 95%;
  display: flex;
  flex-direction: row;
  border: 1px solid #9f9f9f !important;
  border-radius: 4px;
  box-shadow: none;
}

.description-input-wrapper textarea {
  height: 10rem;
  width: 100%;
  background-color: transparent;
  border: none;
}

.description-input-wrapper textarea:focus {
  outline: none;
}

/*----------------------------------*/
/*          Submit Button           */
/*----------------------------------*/
.submit-profile-button {
  margin-top: 20px;
  padding: 10px;
  color: #000;
  background-color: #eeeeee;
  border-width: 0.5px;
  border-radius: 8px;
  font-size: 17px;
  float: right;
}

.submit-profile-button:hover {
  background-color: #ddd;
}

.roles-input-wrapper {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}