/*------------------------------------------------*/
/*                 Projects Group                 */
/*------------------------------------------------*/
.pubcurr-group {
  width: 100%;
  height: fit-content;
  background-color: #f2ecf2;
  border-radius: 5px;
  padding: 20px 20px;
}

.pubcurr-horizontal-line {
  width: 100%;
  height: 1.5px;
  background-color: #d5cad5;
  margin: 8px auto;
}

.pubcurr-vertical-spacer {
  width: 2%;
}

.pubcurr-entry {
  display: flex;
  align-items: center; /* Align items vertically centered */
  width: 100%;
  margin: 10px auto;
  justify-content: space-between;
}

.pubcurr-link {
  display: flex;
  flex-direction: row;
  width: 90%;
  text-decoration: none; /* Add this to remove underline */
  color: inherit; /* Add this to inherit color */
}

.pubcurr-image {
  width: 20%;
  height: fit-content;
  margin-right: 2%;
}

.pubcurr-image img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.pubcurr-desc {
  display: flex;
  flex-direction: column;
  width: 80%; /* Adjusted to take the remaining space */
  margin: auto 0px auto 20px;
}

.pubcurr-desc h2 {
  font-family: "Inter";
  font-size: 16px;
  color: #000000;
  font-style: italic;
  font-variation-settings: "wght" 500;
  line-height: 1.25;
  text-align: left;
}

.pubcurr-desc p {
  font-family: "Inter";
  font-size: 12px;
  color: #373737;
  font-variation-settings: "wght" 400;
  text-align: left;
}

.pubcurr-trash button {
  width: 15%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pubcurr-trash p {
  font-family: "Inter";
  font-size: 12px;
  font-variation-settings: "wght" 400;
  text-align: center;
  margin-right: 5px;
}

/*------------------------------------------------*/
/*                Other User Group                */
/*------------------------------------------------*/
.pubcurr-entry-other {
  display: flex;
  align-items: center; /* Align items vertically centered */
  width: 100%;
  margin: 10px auto;
  justify-content: space-between;
}

.pubcurr-link {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-decoration: none; /* Add this to remove underline */
  color: inherit; /* Add this to inherit color */
}

.pubcurr-desc-other {
  display: flex;
  flex-direction: column;
  width: 90%; /* Adjusted to take the remaining space */
  margin: auto 10px auto 20px;
}

.pubcurr-desc-other h2 {
  font-family: "Inter";
  font-size: 16px;
  color: #000000;
  font-style: italic;
  font-variation-settings: "wght" 500;
  line-height: 1.25;
  text-align: left;
}

.pubcurr-desc-other p {
  font-family: "Inter";
  font-size: 12px;
  color: #373737;
  font-variation-settings: "wght" 400;
  text-align: left;
}

.pubcurr-image-other {
  width: 20%;
  height: fit-content;
}

.pubcurr-image-other img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}
