@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200..900');

h1.signin-header {
    font-family: 'Inter';
    font-size: 1.5rem;
    font-variation-settings: 'wght' 500;
    text-align: center;
    margin-top: 4rem;
}

.signup-container {
    font-family: 'Inter';
    width: 55vw;
    height: fit-content;
    margin: 1.5rem auto;
    display: flex;
    flex: row;
    box-shadow: 0 0 8px #ddd;
}

.signup-horizontal-spacer {
    height: 20px;
  }

.login-container {
    font-family: 'Inter';
    width: 55vw;
    height: 60vh;
    margin: 1.5rem auto;
    display: flex;
    flex: row;
    box-shadow: 0 0 8px #ddd;
}

.signin-left-container {
    width: 50%;
}

.signin-right-container {
    text-align: center;
    width: 50%;
    color: #efefef;
    align-items: center;
}

.signin-right-container h1.login-h1 {
    margin-top: 10rem;
    font-size: 2rem;
    font-variation-settings: 'wght' 700;
    color: #ffffff;
}

.signin-right-container h1.signup-h1 {
    margin-top: 15rem;
    font-size: 2rem;
    font-variation-settings: 'wght' 700;
    color: #ffffff;
}

.signin-right-container p {
    font-size: 1rem;
    font-variation-settings: 'wght' 500;
    color: #ffffff;
}

.signin-right-container button {
    font-size: 1rem;
    font-variation-settings: 'wght' 500;
    margin-top: 1rem;
    padding: 0.3rem 0.6rem;
    border: white solid 1px;
    border-radius: 25px;
}

.signin-left-heading {
    margin: 2rem 0 0 2rem;
}

.signin-left-heading h1 {
    font-size: 1.5rem;
    font-variation-settings: 'wght' 700;
}

.signin-left-heading p {
    font-size: 0.8rem;
    font-variation-settings: 'wght' 300;
}

h1.signin-name-heading {
    font-size: 0.9rem;
    font-variation-settings: 'wght' 500;
    color: #aaa;
}

.signin-name-container {
    display: flex;
    flex-direction: row;
}

.signin-name-input-wrapper {
    margin: 1rem 0.5rem 0 2rem;
    padding: 1rem 0rem 0.1rem;
    width: 35%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #aaa;
    border-radius: 0;
    box-shadow: none;
}

.signin-name-input-wrapper input {
    height: 1.5rem;
    width: 100%;
    margin-top: 0.5rem;
    background-color: transparent;
    border: none;
}

.signin-name-input-wrapper input:focus {
    outline: none;
}

h1.signin-input-heading {
    font-size: 0.9rem;
    font-variation-settings: 'wght' 500;
    color: #aaa;
    margin: 2rem 0 -0.5rem 2rem;
}

.signin-input-wrapper {
    margin-left: 2rem;
    padding: 1rem 0rem 0.1rem;
    width: 80%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #aaa;
    border-radius: 0;
    box-shadow: none;
}

.signin-input-wrapper input {
    height: 1.5rem;
    width: 100%;
    margin-left: 10px;
    background-color: transparent;
    border: none;
}

.signin-input-wrapper input:focus {
    outline: none;
}

#signin-icon {
    margin-top: 2px;
    font-size: 20px;
}

#signin-key-icon {
    font-size: 18px;
}

.signin-submit-button {
    width: 80%;
    padding: 10px;
    color: #fff;
    background-color: #A5BADC;
    border-radius: 5px;
    font-size: 15px;
    font-variation-settings: 'wght' 550;
    margin: 1rem 0 0 2rem;
}

.signin-submit-button:hover {
    background-color: rgb(154, 180, 159);
}

.signin-left-bottom-text {
    margin: 0.5rem 0 0 2rem;
    text-align: center;
    width: 80%;
}

.signin-left-bottom-text p {
    font-size: 0.8rem;
    font-variation-settings: 'wght' 300;
}

.signin-method-icons {
    width: 96%;
    text-align: center;
    margin-top: 0.5rem;
    list-style: none;
}
  
.signin-method-icons li {
    display: inline-block;
}

.signin-method-icons a {
    color: #818a91;
    display: inline-block;
    width: 2.7rem;
    height: 2.7rem;
    text-align: center;
    margin-left: 6px;
    margin-right: 8px;
    border-radius: 100%;
    background-color: #33353d;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.signin-method-icons a.google {
    font-size: 1.4rem;
    background-color: #eceeef;
}

.signin-method-icons a.facebook {
    font-size: 1.2rem;
    color: #fff;
    background-color: #35508b;
}

.signin-method-icons a:focus,
.signin-method-icons a:hover {
    color: #fff;
    background-color: #29aafe;
}
  
.signin-method-icons.size-sm a {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px;
}
  
.signin-method-icons a.facebook:hover {
    background-color: #4b6ec0;
}
  
.signin-method-icons a.google:hover {
    background-color: #d5d5d5;
}

/*-- Error Message Container --*/

.signin-error-message {
    display: flex;
    width: fit-content;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: rgb(150, 0, 0) solid 1px;
    border-radius: 25px;
    align-items: center;
    position: absolute;
    bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.signin-error-message #error-message-icon {
    color: rgb(150, 0, 0);
    margin-right: 0.5rem;
}