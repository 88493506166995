@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.main-container {
  font-family: "Inter";
  height: fit-content;
  width: 100%;
  margin: auto;
  text-align: center;
  overflow-x: hidden;
}

.profile-vertical-spacer {
  width: 4%;
}

.profile-horizontal-spacer {
  height: 30px;
}

.profile-horizontal-line {
  width: 100%;
  height: 2px;
  background-color: #c7c7c7;
  margin: 30px auto;
}

/*------------------------------------------------*/
/*                     Columns                    */
/*------------------------------------------------*/
.columns-group {
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: space-between;
  align-content: flex-start;
  margin-top: 30px;
  padding-left: 6%;
  padding-right: 6%;
}

.profile-left-column {
  align-items: flex-start;
  width: 68%;
  height: fit-content;
}

.profile-left-column h1 {
  margin-bottom: 20px;
  font-family: "Inter";
  font-size: 24px;
  text-align: left;
  font-variation-settings: "wght" 500;
  line-height: 1;
  color: #000000;
}

.profile-right-column {
  display: flex;
  flex-direction: column;
  width: 24%;
  height: fit-content;
  float: right;
}

.profile-right-column h1 {
  margin-bottom: 20px;
  font-family: "Inter";
  font-size: 24px;
  text-align: left;
  font-variation-settings: "wght" 500;
  line-height: 1;
  color: #000000;
}

/*------------------------------------------------*/
/*                 Profile Header                 */
/*------------------------------------------------*/
.pic-column {
  width: 12%;
  align-items: center;
  img {
    width: 100%;
    height: fit-content;
    border-radius: 100%;
    object-fit: cover;
  }
}

.info-column {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 60%;
  height: fit-content;
  float: left;
}

.badge-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24%;
  height: fit-content;
  img {
    width: 100%;
    height: fit-content;
    margin: 5px auto;
    object-fit: cover;
  }
}

.info-column h1 {
  font-family: "Inter";
  font-size: 42px;
  text-align: left;
  font-variation-settings: "wght" 700;
  line-height: 1.25;
  color: #000000;
}

.info-column h2 {
  font-family: "Inter";
  font-size: 18px;
  text-align: left;
  font-variation-settings: "wght" 400;
  line-height: 1.25;
  color: #3f3f3f;
}

.info-buttons {
    width: 100%;
    height: fit-content;
    margin: 10px;
}

.info-buttons p {
  margin: 5px;
  padding: 6px 50px;
  width: fit-content;
  font-family: "Inter";
  font-size: 16px;
  color: #ffffff;
  font-variation-settings: "wght" 500;
  background-color: #6F83B8;
  border-radius: 8px;
  float: left;
}

/*------------------------------------------------*/
/*                  Biography Group               */
/*------------------------------------------------*/
.bio-group {
  width: 100%;
  height: fit-content;
}

.bio-group p {
  font-family: "Inter";
  font-size: 16px;
  text-align: left;
  font-variation-settings: "wght" 450;
  line-height: 1.25;
  color: #000000;
}

/*------------------------------------------------*/
/*               Interest Tag Group               */
/*------------------------------------------------*/
.profile-tag-list-group {
  width: 100%;
  align-items: left;
}

.profile-tag-list-group p {
  margin: 5px;
  padding: 6px 12px;
  width: fit-content;
  font-family: "Inter";
  font-size: 16px;
  color: #000000;
  font-variation-settings: "wght" 500;
  background-color: #bccdee;
  border-radius: 8px;
  float: left;
}

/*------------------------------------------------*/
/*               Helpful Links Group              */
/*------------------------------------------------*/
.helpful-links-group {
  width: 100%;
  align-items: left;
}

.helpful-links-group p {
  font-family: "Inter";
  font-size: 16px;
  color: #000000;
  font-variation-settings: "wght" 500;
  text-align: left;
}

/*------------------------------------------------*/
/*                 Colleague Group                */
/*------------------------------------------------*/
.colleague-group {
  width: 100%;
  align-items: left;
}

.colleague-entry {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: flex-start;
  margin: 5px auto;
}

.colleague-name {
  width: 75%;
  height: fit-content;
}

.colleague-name p {
  font-family: "Inter";
  font-size: 16px;
  color: #000000;
  font-variation-settings: "wght" 450;
  text-align: left;
}

.colleague-count {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: fit-content;
  float: right;
}

.colleague-count p {
  font-family: "Inter";
  font-size: 16px;
  color: #000000;
  font-variation-settings: "wght" 550;
  text-align: right;
}

/*------------------------------------------------*/
/*                 Projects Group                 */
/*------------------------------------------------*/
.pubcurr-group {
  width: 100%;
  max-height: 24.5rem;
  background-color: #f2ecf2;
  border-radius: 5px;
  padding: 20px 20px;
  overflow-y: scroll;
}