@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.site-footer {
  font-family: "Inter";
  font-size: 15px;
  line-height: 24px;
  color: #737373;
  background-color: white;
  text-align: left;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
  margin: auto 10px auto -8px;
  width: 101%;
}

.row {
  display: flex;
  flex-direction: row;
}

.site-footer a {
  color: #737373;
}

.footer-nav {
  position: absolute;
  font-size: 16px;
  margin-top: 1.5rem;
  right: 0;
}

.footer-links {
  padding-left: 0;
  list-style: none;
  margin: 0 0 2rem;
  float: right;
}

.footer-links p {
  display: block;
  margin-right: 110px;
  margin-bottom: 15px;
  color: #737373;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
}

.footer-links.inline li {
  display: inline-block;
}

.footer-logo {
  display: flex;
  flex-direction: column;
}

.footer-logo-img {
  width: 10rem;
  margin: 1rem 2.3rem 0;
  float: left;
  z-index: 1;
}

.copyright-text p {
  margin-left: 20px;
  font-size: 12px;
}

@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}

.social-icons {
  text-align: left;
  margin-left: 4rem;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}

#icon {
  margin: 25% auto;
}

.social-icons a {
  background-color: #eceeef;
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 35px;
  height: 35px;
  text-align: center;
  margin-left: 6px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: #383838;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}

.social-icons a.facebook:hover {
  background-color: #35508b;
}

.social-icons a.twitter:hover {
  background-color: #00b8fb;
}

.social-icons a.linkedin:hover {
  background-color: #0184c1;
}

.social-icons a.dribbble:hover {
  background-color: #de4881;
}

@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}
