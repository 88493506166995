@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.create-post-main-container {
  font-family: "Inter";
  height: fit-content;
  width: 100%;
  align-content: center;
}

.create-post-main-container h1 {
  margin-left: 10vw;
  font-size: 30px;
  font-variation-settings: "wght" 600;
}

.create-post-vertical-spacer {
  height: 2rem;
}

/*------------------------------*/
/*          Inner Block         */
/*------------------------------*/
.create-post-inner-container {
  width: 80%;
  height: fit-content;
  margin: 20px auto;
  background-color: #ffffff;
  box-shadow: 0 0 8px #ddd;
  padding: 40px;
  border-radius: 8px;
}

.create-post-input-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: white;
}

/*----------------------------------------------*/
/*          Create Post Search Feature          */
/*----------------------------------------------*/
.create-post-search-input-wrapper {
  height: 2rem;
  width: 70%;
  padding: 0 15px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #9f9f9f !important;
  border-radius: 4px;
}

.create-post-search-input-wrapper input {
  height: 1.5rem;
  width: 100%;
  margin-left: 10px;
  background-color: transparent;
  border: none;
}

.create-post-search-input-wrapper input:focus {
  outline: none;
}

#basic-icon {
  cursor: pointer;
}

#close-icon {
  margin-left: -0.5rem;
  margin-right: -0.3rem;
  width: 2rem;
  cursor: pointer;
}

.create-post-results-list {
  width: 26%;
  max-height: 10rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 8px #ddd;
  border-radius: 2px;
  margin-top: 2rem;
  overflow-y: scroll;
  position: absolute;
  cursor: pointer;
  z-index: 50;
}

.create-post-search-result {
  padding: 10px 20px;
  text-align: left;
}

.create-post-search-result:hover {
  background-color: #efefef;
}

/*--------------------------------------*/
/*          Inputted Info Items         */
/*--------------------------------------*/
.inputted-item-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  margin-top: 0.5rem;
}

.inputted-item {
  display: flex;
  flex-direction: row;
}

.inputted-item p {
  margin-bottom: 5px;
  padding: 6px 9px 6px 9px;
  font-size: 14px;
  color: #3d3d3d;
  background-color: #bccdee;
  border-radius: 8px;
  font-variation-settings: "wght" 550;
}

.inputted-item #delete-inputted-item {
  margin: 0.5rem 0.5rem 0 0;
  font-size: 1rem;
  cursor: pointer;
}

/*------------------------------*/
/*          Title Input         */
/*------------------------------*/
.title-input-wrapper {
  margin-top: 3rem;
  padding: 0.5rem 1rem 0.5rem;
  width: 95%;
  display: flex;
  flex-direction: row;
  border: 1px solid #9f9f9f !important;
  border-radius: 4px;
  box-shadow: none;
}

.title-input-wrapper input {
  height: 1rem;
  width: 100%;
  background-color: transparent;
  border: none;
}

.title-input-wrapper input:focus {
  outline: none;
}

/*--------------------------------------*/
/*          Description Items           */
/*--------------------------------------*/
.description-input-wrapper {
  margin: 1rem 2rem 0;
  padding: 1rem 1rem 0.5rem;
  width: 95%;
  display: flex;
  flex-direction: row;
  border: 1px solid #9f9f9f !important;
  border-radius: 4px;
  box-shadow: none;
}

.description-input-wrapper input {
  height: 10rem;
  width: 100%;
  background-color: transparent;
  border: none;
}

.description-input-wrapper input:focus {
  outline: none;
}

/*----------------------------------*/
/*          Submit Button           */
/*----------------------------------*/
.submit-post-button {
  margin-top: 20px;
  padding: 10px;
  color: #000;
  background-color: #eeeeee;
  border-width: 0.5px;
  border-radius: 8px;
  font-size: 17px;
  float: right;
}

.submit-post-button:hover {
  background-color: #ddd;
}
