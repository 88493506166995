@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.main-container {
  height: fit-content;
  width: 100%;
  margin: auto;
}

.post-vertical-spacer {
  width: 2%;
}

.post-horizontal-spacer {
  height: 20px;
}

.post-horizontal-line {
  width: 90%;
  height: 2px;
  background-color: #c7c7c7;
  margin: 30px auto;
}

/*------------------------------------------------*/
/*                  Project Post                  */
/*------------------------------------------------*/
.post {
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  border-width: 1.5px;
  border-color: #c5ddf5;
  text-align: left;
  padding: 20px 20px 5px 20px;
  border-radius: 10px;
  justify-content: flex-start;
  /* space between posts v */
  margin-bottom: 20px; 
}

.post-profile-column {
  flex-direction: column;
  align-items: center;
  width: 5%;
  float: left;
  margin-right: 10px;
  img {
    height: auto;
    object-fit: cover;
    border-radius: 100%;
  }
}

.post-info-column {
  width: 73%;
  height: fit-content;
  flex-direction: column;
  float: right;
  display: flex;
}

/*------------------------------------------------*/
/*                 Post Information               */
/*------------------------------------------------*/
.authors {
  display: flex;
  flex-direction: row;
}

.authors p {
  margin: -7px 0 -2px 0;
  font-variation-settings: "wght" 500;
}

.authors p.lab {
  margin-left: 5px;
  font-variation-settings: "wght" 400;
  color: #555;
}

.time {
  font-size: 13px;
  font-variation-settings: "wght" 450;
  color: #6f6f6f;
}

/*------------------------------------------------*/
/*                    Thumbnail                   */
/*------------------------------------------------*/
.thumbnail {
  flex-direction: row;
  display: flex;
}

.thumbnail h1 {
  font-size: 18px;
  text-align: left;
  font-style: italic;
  font-variation-settings: "wght" 550;
}

.post-project-tags {
  margin: 15px auto auto auto;
  align-items: left;
}

.post-project-tags p {
  margin: 8px 0px 0px 8px;
  padding: 6px 12px 6px 12px;
  width: fit-content;
  font-family: "Inter";
  font-size: 14px;
  color: #000000;
  font-variation-settings: "wght" 550;
  background-color: #f5e3ed;
  border-radius: 8px;
  float: left;
}

/*------------------------------------*/
/*          Post Image Column         */
/*------------------------------------*/
.post-image-column {
  flex-direction: row;
  display: flex;
  width: 20%;
  img {
    width: 100%;
    height: 120px;
    border-radius: 5px;
    object-fit: cover;
    margin: auto;
  }
}

/*------------------------------------------------*/
/*                    Comments                    */
/*------------------------------------------------*/
.comments {
  flex-direction: row;
  display: flex;
  align-items: center;
  img {
    width: 20%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
  }
}

.comments p {
  margin-left: 5px;
  font-size: 14px;
  font-variation-settings: "wght" 400;
  color: #555;
}
