@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900");

.main-container {
  font-family: 'Inter';
  height: fit-content;
  width: 100%;
  margin: auto;
}

.home-main-container {
  display: flex;
  flex-direction: row;
}

.home-vertical-spacer {
    width: 1.5%;
}

.home-horizontal-spacer {
  height: 1%;
}

.home-horizontal-line {
  width: 100%;
  height: 1.5px;
  background-color: #c7c7c7;
  margin: 30px auto;
}

/*------------------------------------------------*/
/*                  Refine Column                 */
/*------------------------------------------------*/
.refine-column {
  width: 22%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
}

/*-- Nav --*/

.refine-column-nav {
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  border-radius: 10px;
  height: fit-content;
}

.refine-column-nav .page {
  display: flex;
  flex-direction: row;
  height: 3.5rem;
  padding: 15px 10px;
  color: #444;
}

.page #icon {
  font-size: 25px;
  margin: 0 0 0 1rem;
}

.page p {
  font-size: 17px;
  font-variation-settings: "wght" 600;
  margin: 0 0 0 1rem;
}

/*-- Filters --*/

.refine-column-filters {
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #e0e7f0;
  border-radius: 10px;
}

.refine-column-filters h1 {
  font-size: 18.3px;
  font-variation-settings: "wght" 650;
  padding: 0.5rem;
  text-align: left;
}

.refine-column-filters .post-type {
  display: inline-block;
  margin: 10px 0 0 0px;
}

#post-type-selector {
  width: 90%;
  height: fit-content;
  margin: auto;
  padding: 7px 0;
  border: none;
  background-color: #f7f7f7;
  border-radius: 10px;
  overflow: hidden;
}

#post-type-selector input[type="checkbox"] {
  appearance: none;
}

#post-type-selector span {
  font-size: 1rem;
  padding: 2% 6%;
  margin: 0 1%;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-variation-settings: "wght" 400;
  color: #000;
}

#post-type-selector input[type="checkbox"]:checked + span {
  background-color: #e6e6e6;
}

.refine-column-filters .tags-container {
  height: fit-content;
  display: block;
}

.tags-container .tags {
  height: fit-content;
}

.tags p.tag {
  margin: 0px 0 8px 8px;
  padding: 6px 9px 6px 9px;
  width: fit-content;
  font-size: 14px;
  color: #3d3d3d;
  background-color: #bccdee;
  border-radius: 8px;
  font-variation-settings: "wght" 550;
  float: left;
}

/*------------------------------------------------*/
/*                   Feed Column                  */
/*------------------------------------------------*/
.feed-column {
  height: fit-content;
  height: 85vh;
  width: 75%;
  overflow-y: scroll;
}

.feed-column::-webkit-scrollbar {
  display: none;
}

.posts {
  display: block;
}

.home-tag-row {
  height: 50px;
  display: flex;
  flex-direction: row;
}

.home-tag-row h1 {
  font-size: 20px;
  font-variation-settings: "wght" 700;
  text-align: left;
}

/*------------------------------------------------*/
/*                    ? Column                    */
/*------------------------------------------------*/
.suggestions-column {
  width: 20%;
  height: 87vh;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px;
}

.suggestions-column::-webkit-scrollbar {
  display: none;
}

.suggestions-column .suggested-profiles {
  max-height: 50vh;
  padding: 10px;
  /* background-color: #f5dec5;
  border-width: 3px;
  border-color:#f5dec5;
  filter: drop-shadow(0 0 0.5rem #f5dec5); */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.suggestions-column .suggested-profiles h1 {
  font-size: 20px;
  font-variation-settings: "wght" 600;
  margin: 10px 0;
}

.profile-entry {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.profile-entry img{
  margin: 10px 0px 10px 15px;
  width: 3rem;
  border-radius: 50%;
}

.profile-entry .profile-entry-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0.75rem 0 0 0.75rem;
}

.profile-entry h2 {
  color: #000;
  font-size: 15px;
  font-variation-settings: "wght" 450;
}

.profile-entry p {
  color: #5e5e5e;
  font-size: 12px;
}

.suggestions-column .disclaimer-text {
  font-size: 12px;
  text-align: left;
}